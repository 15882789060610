@font-face {
  font-family: 'custom-icon';
  src: url('./custom-icon.eot?1nkvu7');
  src: url('./custom-icon.eot?1nkvu7#iefix') format('embedded-opentype'),
    url('./custom-icon.ttf?1nkvu7') format('truetype'),
    url('./custom-icon.woff?1nkvu7') format('woff'),
    url('./custom-icon.svg?1nkvu7#custom-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use  to prevent issues with browser extensions that change fonts */
  font-family: 'custom-icon', sans-serif;
  /* speak: none; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dnis:before {
  content: "\e001";
  color: #ff7b00;
}

.icon-miq-logo .path1:before {
  content: "\e002";
  color: rgb(255, 255, 255);
}

.icon-miq-logo .path2:before {
  content: "\e003";
  margin-left: -1em;
  color: rgb(255, 123, 0);
}

.icon-share:before {
  content: "\e900";
}

.icon-bookmark:before {
  content: "\e004";
}

.icon-fullscreen:before {
  content: "\e005";
}

.icon-normalscreen:before {
  content: "\e006";
}

.icon-reset:before {
  content: "\e007";
}

.icon-long-left-arrow:before {
  content: "\e008";
}

.icon-undo:before {
  content: "\e009";
}

.icon-download:before {
  content: "\e010";
}

.icon-filter-svg:before {
  content: "\e011";
}

.icon-thumbnail-more:before {
  content: "\e012";
}

.icon-thumbnail-few:before {
  content: "\e013";
}

.icon-audio-meter:before {
  content: "\e014";
}

.icon-back:before {
  content: "\e015";
}

.icon-front:before {
  content: "\e016";
}

.icon-bottom:before {
  content: "\e017";
}

.icon-top:before {
  content: "\e018";
}

.icon-close-svg:before {
  content: "\e019";
}

.icon-add:before {
  content: "\e020";
}

.icon-down:before {
  content: "\e021";
}

.icon-right:before {
  content: "\e022";
}

.icon-up:before {
  content: "\e023";
}

.icon-left:before {
  content: "\e024";
}

.icon-down1:before {
  content: "\e025";
}

.icon-pause:before {
  content: "\e026";
}

.icon-video-forward:before {
  content: "\e027";
}

.icon-video-previous:before {
  content: "\e028";
}

.icon-zoom:before {
  content: "\e029";
}

.icon-arrow-bottom:before {
  content: "\e030";
}

.icon-arrow-top:before {
  content: "\e031";
}

.icon-arrow-left:before {
  content: "\e032";
}

.icon-arrow-right:before {
  content: "\e033";
}

.icon-as-run:before {
  content: "\e034";
}

.icon-details-view:before {
  content: "\e035";
}

.icon-captions:before {
  content: "\e036";
}

.icon-notifications:before {
  content: "\e037";
}

.icon-play-channel:before {
  content: "\e038";
}

.icon-trim:before {
  content: "\e039";
}

.icon-clip:before {
  content: "\e040";
}

.icon-help-available:before {
  content: "\e041";
}

.icon-loudness:before {
  content: "\e042";
}

.icon-mutted:before {
  content: "\e043";
}

.icon-monitor:before {
  content: "\e044";
}

.icon-clips:before {
  content: "\e045";
}

.icon-recording:before {
  content: "\e047";
}

.icon-search:before {
  content: "\e048";
}

.icon-traffic:before {
  content: "\e049";
}

.icon-left-expand:before {
  content: "\e050";
}

.icon-right-expand:before {
  content: "\e051";
}

.icon-frames:before {
  content: "\e052";
}

.icon-ac-3:before {
  content: "\e053";
}

.icon-nac:before {
  content: "\e054";
}

.icon-caption:before {
  content: "\e055";
}

.icon-annotation:before {
  content: "\e056";
}

.icon-cue-trigger:before {
  content: "\e057";
}

.icon-close-prev-frames:before {
  content: "\e058";
}

.icon-next-subframes:before {
  content: "\e059";
}

.icon-prev-subframes:before {
  content: "\e060";
}

.icon-star-half:before {
  content: "\e061";
}

.icon-video-recorder:before {
  content: "\e062";
}

.icon-add1:before {
  content: "\e063";
}

.icon-minus:before {
  content: "\e064";
}

.icon-plus:before {
  content: "\e065";
}

.icon-flagright:before {
  content: "\e066";
}

.icon-flagleft:before {
  content: "\e067";
}

.icon-scissor:before {
  content: "\e068";
}

.icon-compare:before {
  content: "\e069";
}

.icon-paragraph:before {
  content: "\e070";
}

.icon-export:before {
  content: "\e071";
}

.icon-reports:before {
  content: "\e072";
}

.icon-warning:before {
  content: "\e073";
}

.icon-alert-notifications:before {
  content: "\e074";
  color: #ff483b;
}

.icon-ads:before {
  content: "\e057";
}

.icon-adbreak:before {
  content: "\e105";
}

.icon-audio:before {
  content: "\e077";
}

.live-icon:before {
  content: ".";
  top: 5px;
  color: #00d76a;
}
.live-icon-red:before {
  content: ".";
  top: 5px;
  color: red;
}
.live-icon-orange:before {
  content: ".";
  top: 5px;
  color: orange;
}
