@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import './sass/variables';
@import './sass/mixins';
@import "video.js/dist/video-js.min.css";
@import './assets/fonts/custom-icons/custom-icons.scss';
@import '../src/assets/fonts/montserrat/theme-font.scss';
@import '../src/assets/fonts/roboto/roboto-font.scss';
@import '../src/assets/fonts/roboto/roboto-font.scss';
@import '../src/assets/fonts/material-icons/material-icons.scss';
@import "~nouislider/dist/nouislider.min.css";

// @import url("https://fonts.googleapis.com/icon?family=Material+Icons");

html,
body {
    margin: 0;
    padding: 0;
    font-family: $theme-font;
    height: 100%;
    overflow: hidden;
    background: $theme-bg2;
}

* {
    font-family: $theme-font;
}



a {
    color: $theme-color;
    text-decoration: none !important;

    &:hover {
        color: $theme-color;
        // color: lighten($theme-color, 20%);
    }
}

.img-icon {
    width: 100%;
    max-width: 30px;
}

.cloud__download--btn {
    color: $white-color;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-white {
    color: $text-white !important;
}

.text-red {
    color: $text-red !important;
}

.text-grey {
    color: #cccccc !important;
}



.button__disabled {
    background: $theme-bg2;
    pointer-events: none;
    color: $text-disabled;
}

.rotate-180 {
    transform: rotate(180deg);
}

.no-border-radius {
    border-radius: 0 !important;
}

.border-1 {
    border-radius: 2px;
}

.rounded {
    border-radius: 50%;
}

/*all browser trackbar css*/
// ::scrollbar {
//     width: 7px;
//     height: 7px;
// }

// ::scrollbar-thumb {
//     border-radius: 3px;
//     background-color: $theme-color;
// }

// ::scrollbar-track {
//     background: #666666;
// }

/*chrome trackbar css*/
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #aaaaaa;
}

::-webkit-scrollbar-track {
    background: #666666;
}

/*mozilla trackbar css*/
::-moz-scrollbar {
    width: 7px;
    height: 7px;
}

::-moz-scrollbar-thumb {
    border-radius: 3px;
    background-color: #aaaaaa;
}

::-moz-scrollbar-track {
    background: #666666;
}

/*safari trackbar css*/
::-ms-scrollbar {
    width: 7px;
    height: 7px;
}

::-ms-scrollbar-thumb {
    border-radius: 3px;
    background-color: #aaaaaa;
}

::-ms-scrollbar-track {
    background: #666666;
}

* {
    font-family: $theme-font;
}

.cursor-pointer {
    cursor: pointer;
}

nav {
    .right-nav {
        .mat-menu {
            background: #202124;
            width: 150px;

            button {
                color: #808080 !important
            }
        }
    }
}

.meta-highlight {
    //background-color: #5b5e60;
    background-color: $button-disabled;
    padding: 2px 3px 2px 4px;
    font-style: initial;
}

.mat-menu-panel {
    .mat-menu-content {
        background: #444444;
        width: 168px;
        padding: 0 !important;

        button {
            color: #ffffff !important;
            font-size: 12px;
            padding: 11px 12px !important;
            height: auto !important;
            line-height: 16px !important;
            font-family: $theme-font;

            &:nth-child(odd) {
                background: #555555;
            }
        }
    }
}

.hidden__field {
    display: none !important;
}

.d-flex {
    display: flex;
}

.flex-end {
    justify-content: flex-end;
}

.flex-start {
    justify-content: flex-start;
}

.flex-align-center {
    align-items: center;
}

.d__grid {
    display: grid;
    grid-template-columns: auto auto auto;
}

.d__grid--item {
    padding: 20px;
}

.flex-1 {
    flex: 1;
}

.ci-2x {
    font-size: 20px;
}

.m-auto {
    margin: auto;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.m-0 {
    margin: 0;
}

.ml-0 {
    margin-left: 0;
}

.mr-0 {
    margin-right: 0;
}

.mt-0 {
    margin-top: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.p-0 {
    padding: 0;
}

.p-1 {
    padding: .25rem;
}

.p-2 {
    padding: .5rem;
}

.p-3 {
    padding: 1rem;
}

.p-4 {
    padding: 1.5rem;
}

.p-5 {
    padding: 3rem;
}

.px-0 {
    padding-left: 0;
    padding-right: 0;
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pb-1,
.py-1 {
    padding-bottom: .25rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: .5rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pt-1,
.py-1 {
    padding-top: .25rem !important;
}

.pt-2,
.py-2 {
    padding-top: .5rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pr-1,
.px-1 {
    padding-right: .25rem !important;
}

.pr-2,
.px-2 {
    padding-right: .5rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.pl-1,
.px-1 {
    padding-left: .25rem !important;
}

.pl-2,
.px-2 {
    padding-left: .5rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.mb-1,
.my-1 {
    margin-bottom: .25rem !important;
}

.mb-2,
.pmy-2 {
    margin-bottom: .5rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mt-1,
.my-1 {
    margin-top: .25rem !important;
}

.mt-2,
.my-2 {
    margin-top: .5rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mr-1,
.mx-1 {
    margin-right: .25rem !important;
}

.mr-2,
.mx-2 {
    margin-right: .5rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mr-5,
.my-5 {
    margin-right: 3rem !important;
}


.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.ml-1,
.mx-1 {
    margin-left: .25rem !important;
}

.ml-2,
.mx-2 {
    margin-left: .5rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.ul__inline--0 {
    padding-inline-start: 0;
}

input,
textarea {
    // caret-color: #ffffff !important;
    padding: 0 10px !important;
}


input {
    height: 40px;
}

input:focus {
    border-bottom: 2px solid $theme-color;
}


input::placeholder {
    color: #aaaaaa !important;
}


.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}


// [class*="col"] {
//   flex-direction: column;
//   display: flex;
// }

.h-100 {
    height: 100%;
}

.w-10 {
    width: 10%;
}

.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}

.w-30 {
    width: 30%;
}

.w-33 {
    width: 33.33%;
}

.w-40 {
    width: 40%;
}

.w-50 {
    width: 50%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.w-100 {
    width: 100%;
}

.w-50px {
    width: 50px;
    min-width: 50px;
}

.w-60px {
    width: 60px;
    min-width: 60px;
}

.w-70px {
    width: 70px;
    min-width: 70px;
}

.w-80px {
    width: 80px;
    min-width: 80px;
}

.w-90px {
    width: 90px;
    min-width: 90px;
}

.w-100px {
    width: 100px;
    min-width: 100px;
}

.w-110px {
    width: 110px;
    min-width: 110px;
}

.w-120px {
    width: 120px;
    min-width: 120px;
}

.w-130px {
    width: 130px;
    min-width: 130px;
}

.w-140px {
    width: 140px;
    min-width: 140px;
}

.w-150px {
    width: 150px;
    min-width: 150px;
}

.w-160px {
    width: 160px;
    min-width: 160px;
}

.w-170px {
    width: 170px;
    min-width: 170px;
}

.w-180px {
    width: 180px;
    min-width: 180px;
}

.w-190px {
    width: 190px;
    min-width: 190px;
}

.w-200px {
    width: 200px;
    min-width: 200px;
}

.w-210px {
    width: 210px;
    min-width: 210px;
}

.w-220px {
    width: 220px;
    min-width: 220px;
}

.w-230px {
    width: 230px;
    min-width: 230px;
}

.w-240px {
    width: 240px;
    min-width: 240px;
}

.w-250px {
    width: 250px;
    min-width: 250px;
}

.w-300px {
    width: 300px;
    min-width: 300px;
}

.flex-start {
    align-items: flex-start;
}

.flex-end {
    align-items: flex-end;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-column-reverse {
    flex-direction: column-reverse;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

// .overflow-x-auto {
//   overflow-x: auto;
// }

// .overflow-y-auto {
//   overflow-y: auto;
// }

// .overflow-x {
//   overflow-x: auto;
// }

// .overflow-y {
//   overflow-y: auto;
// }

button {
    outline: none !important;
    border: 0;
}

.form-control {
    transition: none !important;
    box-shadow: none !important;
}

.file-input-group {
    align-items: flex-end;

    .mat-form-field {
        position: relative;



        .remove-btn {
            position: absolute;
            right: 0;
            bottom: 0;
            background: transparent;
            color: #fff;
            align-items: center;
            padding: 8px 6px 4px 6px;
        }
    }

    .upload-btn {
        height: 40px;
        width: 40px;
        padding: 0 !important;
    }
}

.mat-form-field-underline {
    display: none;
}

.mat-form-field-infix {
    padding: 0 !important;
    border-top: 0;
}

label {
    color: #aaaaaa;
    margin-bottom: 0;
    font-size: 14px;
}

// *** GLOBAL MAT OVERRIDED CSS
.mat-expansion-indicator {
    &:after {
        color: #aaaaaa;
    }
}

.mat-tab-group .mat-ink-bar,
.mat-tab-nav-bar .mat-ink-bar {
    background-color: $theme-color !important;
}

.mat-tab-header-pagination {
    //background: $theme-color;
    color: $text-white;

    .mat-tab-header-pagination-chevron {
        border-color: $white-color;
    }
}

.mat-slide-toggle {
    height: 24px;

    //   height: 40px;
    // line-height: 40px;
    .mat-slide-toggle-label {
        margin-bottom: 0 !important;
        height: 24px;

        .mat-slide-toggle-bar {
            width: 42px;
            height: 24px;
            border-radius: 25px;

            .mat-slide-toggle-input {
                left: 0;
                top: 0;
            }

            .mat-slide-toggle-thumb-container {
                width: 18px;
                height: 18px;
                top: 3px;
                left: 3px;

                .mat-slide-toggle-thumb {
                    height: 18px;
                    width: 18px;
                }

                .mat-slide-toggle-ripple {
                    display: none !important;
                }
            }
        }
    }
}

.slider-input-group {
    // align-items: flex-end;

    label {
        color: #aaaaaa;
        font-size: 14px;
    }

    .slider-percentage {
        height: 40px;
        // width: 40px;
        padding: 12px 10px !important;
        background: #666666;
        color: #ffffff;
        font-size: 12px;
        border-radius: 3px;

        span {
            color: #cccccc;
        }
    }
}

.mat-slider {
    &:hover .mat-slider-track-background {
        background: #aaaaaa;
    }

    &.cdk-focused .mat-slider-track-background {
        background: #aaaaaa;
    }

    &.mat-slider-horizontal {
        .mat-slider-wrapper {
            height: 4px;
            border-radius: 3px;

            .mat-slider-track-wrapper {
                height: 4px;
                border-radius: 3px;

                .mat-slider-track-background {
                    height: 4px;
                    border-radius: 3px;
                    background-color: #666666;
                }

                .mat-slider-track-fill {
                    height: 4px;
                    border-radius: 3px;
                }
            }

            .mat-slider-ticks-container {
                height: 4px;

                .mat-slider-ticks {
                    height: 4px;
                }
            }
        }
    }

    &.mat-accent {

        .mat-slider-thumb,
        .mat-slider-thumb-label {
            background-color: $theme-color !important;
            border: 1px solid #ffffff;
        }

        .mat-slider-track-fill {
            background: #ffffff;
        }
    }

    &.mat-slider-min-value:not(.mat-slider-thumb-label-showing) {
        .mat-slider-wrapper {
            .mat-slider-thumb-container {
                .mat-slider-thumb {
                    border-color: #666666;
                    background-color: #666666;
                }
            }
        }
    }
}

// Tooltip style
.custom-tooltip {
    background: #202124c7;
    font-family: $theme-font;
}

// End:: Tooltip style

// .mat-slider-track-background {
//   background-color: #aaaaaa;
// }
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background: $theme-color !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: $theme-color;
}

.mat-select-panel-wrap .mat-select-panel .mat-option-multiple.mat-active {
    background: none !important;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
    background: $theme-color;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: $theme-color;
}

.mat-radio-button {
    .mat-radio-label {
        .mat-radio-container {
            width: 24px !important;
            height: 24px !important;

            .mat-radio-outer-circle {
                /* border-color: transparent !important; */
                background: #dddddd;
                width: 18px !important;
                height: 18px !important;
                border-radius: 50% !important;
                border: 0;
            }

            .mat-radio-inner-circle {
                height: 18px;
                width: 18px;
                background: #ffffff !important;
            }
        }
    }

    &.mat-radio-checked {
        .mat-radio-outer-circle {
            background: $theme-color !important;
            box-shadow: inset 0 0 0 transparent !important;
        }
    }
}



.belowFourSlides .owl-stage>.owl-item {
    width: 25% !important;
}

.theme-btn {
    background-color: $theme-color !important;
    color: #ffffff !important;
}

.theme__btn--outline {
    border: solid 1px #ff7b00 !important;
    color: #ff7b00 !important;
}

.default-btn {
    background: $button-default;
    color: $text-white;
}

.theme__color {
    color: $theme-color !important;
}

.mat-button {
    font-weight: 500;
    box-shadow: none;
    border-radius: 2px !important;
    //min-width: 40px !important;
    padding: 0 10px !important;
}

.no-ripple .mat-ripple {
    display: none;
}

.mat-raised-button {
    background-color: $button-default;
    color: $text-white;
    font-weight: 500;
    box-shadow: none;
    //min-width: 40px !important;
    padding: 0 10px !important;
    border-radius: 3px !important;
}

.mat-raised-button[disabled][disabled],
.mat-button[disabled][disabled] {
    background-color: $button-disabled;
    color: $text-disabled;
}


.mat-checkbox {
    label {
        margin-bottom: 0;
        color: #ffffff;
        font-size: 12px;
    }

    .mat-checkbox-layout {
        .mat-checkbox-inner-container {
            height: 16px;
            width: 16px;
        }
    }
}

.mat-checkbox .mat-ripple-element,
.mat-checkbox-frame {
    border-color: lighten($black-color, 50%);
    border-width: 1px !important;
}

.mat-checkbox-background {
    border-color: lighten($black-color, 80%);
    background-color: lighten($black-color, 80%);
}

.mat-menu-item {
    line-height: 32px !important;
    height: 32px !important;
    padding: 0 12px !important;
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
    line-height: 2em !important;
    height: 2em !important;

    .mat-option-text {
        font-size: 0.9rem;
    }


}

.mat-select-value {
    color: $white-color;
    max-width: 100%;
    padding: 0 10px;
}

.mat-select .mat-select-trigger .mat-selected-value-text {
    color: $text-white;
}

.mat-select-arrow {
    color: $text-white;
}

.mat-select-panel-wrap {
    .mat-select-panel {
        background: lighten($black-color, 33%);
        min-width: 100%;
        position: absolute;

        .mat-option {
            .mat-option-text {
                color: $text-white;
                font-size: 0.8rem;
            }
        }

        .mat-active {
            background: $theme-color !important;
        }

        .mat-option:nth-child(even) {
            background: lighten($black-color, 27%);
        }
    }
}

.mat-slide-toggle {
    overflow: hidden;

    .mat-slide-toggle-bar {
        background-color: #666666;
    }

    .mat-slide-toggle-thumb {
        background-color: #aaaaaa;
    }

    &.mat-checked {
        .mat-slide-toggle-bar {
            background-color: rgb(255 123 0);
        }

        .mat-slide-toggle-thumb {
            background-color: #ffffff;
        }
    }
}

.mat-dialog-container {
    background: $modal-bg;
    border-radius: $modal-radius;
    position: relative;

    .mat-h2,
    .mat-title,
    .mat-typography h2 {
        font-family: $theme-font;
    }

    .close-icon {
        top: 10px;
        right: 10px;
        min-width: 30px !important;
        min-height: 30px;
        border-radius: 50% !important;
        position: absolute;
        color: #fff;
        display: inline-block;
        padding: 0 !important;
        background: #252627;
        max-height: 30px !important;
        font-size: 20px;
        line-height: 19px;
    }

    .mat-dialog-title {
        color: $text-light-gray;
        font-size: $modal-title-size;
        font-family: $theme-font;
        font-weight: 600;
        padding-left: 15px;
        counter-reset: $white-color;
    }

    .modal-container {
        min-width: 450px;
    }

    .mat-dialog-actions {
        button {
            min-width: 120px;
            height: 48px;
            font-size: 16px;
        }
    }
}

p {
    font-size: 14px;
    color: #aaaaaa;
}

.div-table {
    color: #aaaaaa;
    max-height: 100%;
    width: 100%;

    .body-max-height {
        max-height: calc(100vh - 400px);
    }

    .div-table-head {
        min-height: 40px;
        max-height: 60px;

        .div-table-row {
            background-color: #202124;

            .div-table-data {
                padding: 10px 10px 10px 15px;
                font-weight: 700;
                font-size: 14px;
                min-height: 40px;
                box-sizing: border-box;
                align-items: center;
                display: flex;

                span {
                    font-weight: normal;
                }
            }
        }
    }

    .div-table-body {
        background-color: #282a2d;
        height: 500px;
        // overflow-y: auto;

        .div-table-row {

            // &:nth-child(odd){
            //   background-color: #282a2d;
            // }
            &:nth-child(even) {
                background-color: #202124;
            }

            .div-table-data {
                padding: 5px 10px 5px 15px;
                font-size: 12px;
                white-space: nowrap;
                align-items: center;
                height: 32px;
                box-sizing: border-box;
                text-overflow: ellipsis;
                overflow: hidden;
                // display: flex;
                line-height: 22px;

                i {
                    font-size: 20px !important;
                }
            }

            .actions {
                min-width: 80px;
                display: flex !important;
                justify-content: flex-end;
            }
        }
    }

    .actions {
        button {
            background: transparent;
            color: #fff;
            cursor: pointer;
            display: flex;
            padding: 0;

            i {
                font-size: 20px !important;
            }
        }
    }

    &.flex-table {
        .div-table-data {
            flex: 1;
            // width: auto;
            min-width: auto;
        }
    }
}

.unit-field {
    .mat-form-field-infix {
        .unit {
            position: absolute;
            right: 0;
            top: auto;
            bottom: 0;
            align-items: center;
            display: flex;
            font-size: 12px;
            color: #cccccc;
            padding: 13px 10px;
            background: $theme-bg4;
            box-sizing: border-box;
            border-radius: 3px;
        }
    }
}

// .routing-container {
//   &:host{
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//   }
// }

// .head {
//   padding: 13px;
//   background: #444444;
//   h3 {
//       font-size: 20px;
//       margin: 0;
//       padding: 4px;
//       color: #cccccc;
//   }
//   button {
//       height: 32px;
//       line-height: 23px;
//       padding: 0px 10px !important;
//       font-size: 12px;
//   }
// }

.selection-block {
    .cust-form-group {
        flex: 1;

        label {
            font-size: 12px;
        }

        .mat-select {
            height: 36px;
            background: #323639;
            padding: 0.46rem;
        }
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;

        .mat-form-field-flex {
            background: #323639;
            border-radius: 3px 3px;

            // height: 36px;
            .mat-form-field-infix {
                input {
                    background: #323639 !important;
                    height: 36px !important;
                }

                .mat-form-field-label-wrapper {
                    .mat-form-field-label {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }

    .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
        margin-top: -0.5em !important;
    }
}

.cust-form-group {
    // flex: 1;
    width: 100%;
    padding-top: 17px;
    // width: 350px;

    &.mat-focused {
        .mat-form-field-label {
            color: #aaaaaa;
        }

    }

    label {
        color: #aaaaaa;
        padding: 5px 0;
        display: flex;
        font-size: 14px;
        position: initial;
        transform: none !important;
        margin-bottom: 0;
    }

    .mat-select {
        padding: 0.6rem;
        background: $input-bg;
        border-radius: 3px;
        color: #ffffff;
        width: 100%;
        box-sizing: border-box;
        height: 40px;
    }

    .mat-select-arrow-wrapper {
        transform: none !important;
        display: flex;

        .mat-select-arrow {
            color: #ffffff;
            width: 7px;
            height: 7px;
            display: inline-block;
            background: transparent;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            border-top: 0;
            border-left: 0;
            transform: rotate(45deg);
        }
    }

    .mat-form-field-infix {
        width: 100% !important;
        padding: 0 !important;
        border: 0;
        display: flex;
        flex-direction: column-reverse;

        .mat-form-field-label-wrapper {
            position: relative;
            padding: 0;
            display: flex;
            top: 0;
            margin-top: -17px;

            .mat-form-field-label {
                margin-top: 0;
            }
        }

        .mat-input-element {
            padding: 0.6rem;
            color: #ffffff;
            font-size: 12px;
            line-height: 1.15rem;
            box-sizing: border-box;
        }
    }

    .mat-form-field-suffix {
        .mat-icon-button {
            // height: 2.25em;
            color: #ffffff;
            width: 2.33em !important;
            height: 2.33em !important;
            background: #323639;
            height: 36px;
            border-radius: 0 3px 3px 0;
        }
    }

    .mat-select-value {
        color: $text-white !important;
        font-size: 12px;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;

        .mat-form-field-flex {
            padding: 0 !important;
            align-items: center;
            border: 0;
            display: flex;
            box-sizing: border-box;

            // height: 36px;
            .mat-form-field-infix {
                .mat-form-field-label-wrapper {
                    label {
                        color: #aaaaaa;
                    }
                }

                input {
                    background: $input-bg;
                    border-radius: 3px 3px;
                    height: 40px;
                }

                textarea {
                    background: $input-bg;
                    border-radius: 3px 3px;

                }
            }
        }
    }

    .mat-form-field-underline {
        display: none;
    }
}

.multi-checkboxe-selection {
    ul {
        width: 100%;
        padding: 0 15px;
        column-count: 4;

        li {
            display: block;

            .mat-checkbox {
                width: 100%;
                display: inline-block;
                margin-bottom: 2px;
                border: 1px solid transparent;

                label {
                    width: 100%;
                    display: inline-block;
                    padding: 5px 10px;
                }

                .mat-checkbox-layout {
                    .mat-checkbox-inner-container {
                        display: none;
                    }
                }

                &.mat-checkbox-checked {
                    background: $nav-active-bg;
                    border: 1px solid $nav-active-bg-border-color;
                    border-radius: 2px;
                }
            }
        }
    }
}

.overflow-content-td {
    .text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        padding: 0;
        margin: 0;
    }

    ul.text {
        li {
            display: inline-block;
            padding-right: 5px;
        }
    }
}

.toggleBtnSec {
    font-size: 13px;
    display: flex;
    align-items: center;
    height: 40px;
    color: #aaaaaa;

    .mat-slide-toggle-label {
        padding: 0;
        margin: 0 8px;
    }
}


.trigger-block,
.contact-tabs {
    .mat-tab-header {
        margin-bottom: 15px;
        border-bottom: 0;
    }

    .mat-tab-group {
        .mat-tab-label {
            color: #ffffff;
            font-size: 16px;
            min-width: 80px;
            opacity: 1;
            padding-left: 0;
            padding-right: 0;
            margin-right: 20px;
        }

        .mat-tab-label.mat-tab-label-active {
            color: $theme-color !important;
            font-weight: 700;
        }
    }
}

.share-block {
    .mat-tab-header {
        margin-bottom: 15px;
        border-bottom: 0;
    }

    .mat-tab-group {
        .mat-tab-label {
            color: #ffffff;
            font-size: 16px;
            min-width: 80px;
            opacity: 1;
            padding-left: 0;
            padding-right: 0;
            margin-right: 48px;
        }

        .mat-tab-label.mat-tab-label-active {
            color: $theme-color !important;
            font-weight: 700;
        }
    }

    .mat-form-field-label-wrapper mat-label {
        display: flex;
        width: 100%;

        a {
            color: $theme-color;
            font-size: 11px;
            margin-left: auto;
            text-decoration: underline !important;
        }
    }
}

// Preferences page dragable sorting css

.drag-tr {
    background-color: #444444;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    margin-top: 2px;
    display: flex;
    align-items: center;

    .drag-td {
        font-size: 12px;
        height: 48px;
        line-height: 40px;
        color: #aaaaaa;
        padding: 5px 10px 5px 15px;
        white-space: nowrap;
        align-items: center;
        box-sizing: border-box;
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;

        .ci {
            font-size: 20px;
        }

        .drag-btn {
            background-color: transparent;
            border: 0;
            display: flex;
            cursor: move;

            .ci {
                font-size: 27px;
                color: #808080;
            }

        }
    }

    &.cdk-drag-preview {
        background-color: #683a1a;
    }

    &.cdk-drag-placeholder {
        opacity: 0;
    }
}

// main-container
.main__container {
    display: flex;
    height: calc(100vh - 60px);
}

.page__container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    // overflow: auto;
    scroll-behavior: smooth;
    transition: all 0.3s linear;
}

@media all and (max-width: 1024px) {
    .overflow-y {
        overflow-y: auto;
    }
}

.input-bg {
    background: $input-bg;
}

.input-text {
    color: $text-light-gray;
}

//sidebar filter styles
::ng-deep .sidebar__filter {
    height: 100vh;
}
.filter_open {
    display: none;
}
.filterToggleBtn.active {
    span.ci.icon-filter-svg {
        display: none;
    }
    background: #666666 !important;
    .filter_open {
        display: block !important;
    }
}
//datepicker global style
.choose__date--field .mat-form-field-flex {
    align-items: center !important;
}

.mat-calendar-table {
    .ngx-mat-calendar-body {
        .mat-calendar-body-cell {
            .mat-calendar-body-selected {
                background: $theme-color;
            }
        }
    }
}

.ngx-mat-timepicker .mat-form-field-wrapper {
    padding: 0;
}

//date picker final select button style
.mat-datepicker-content>.actions>.mat-primary {
    color: #ff7b00 !important;
}

.ngx-mat-timepicker input {
    padding: 0 !important;
    height: auto;
}

//end:: datepicker global style
// corosoul styles

.ngucarousel {

    .corosoul__container--item {
        width: 100% !important;
        position: relative;
        min-width: 300px;

        .tile {
            box-shadow: none;
        }

        .recordings__list--item {
            width: 100% !important;
        }

    }

    .corosoul__btn {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 40px;
        border-radius: 50%;
        width: 40px;
        z-index: 1;
        background: $black-color;
        color: $text-white;

        &:hover {
            background: $theme-color;
            color: $white-color;
        }
    }

    .corosoul__btn--left {
        left: 0;
    }

    .corosoul__btn--right {
        right: 0;
    }

}

// end:: corosoul styles
.flex-container {
    display: flex;
    flex-direction: row;
    font-size: 30px;
    text-align: center;
    flex-wrap: nowrap;
}

.flex-20 {
    flex: 20%;
}

.flex-30 {
    flex: 20%;
}

.flex-40 {
    flex: 20%;
}

@media (max-width:768) {
    .flex-container {
        flex-direction: column;

    }
}

//live icons color
.live-dot {
    color: #00d76a;
    font-size: 11.5px;
}


// mat dialog styles

.mat-dialog-container {
    max-width: 860px;
    min-width: 828px;


    .mat__dialog--header {
        align-items: center;
        justify-content: space-between;
    }

    // .mat__dialog--close {
    //     background: #252627;
    //     color: $white-color;
    //     width: 40px;
    //     height: 40px;
    // }
}

//mat dialog styles ends here


.download__popup {
    width: 240px;
    min-height: 200px;
    background: $theme-bg1 !important;
    padding: 15px;
    box-sizing: border-box;
    position: relative;
    border-radius: 0 !important;
    box-shadow: 0 0 3px #808080;

    .download__popup--heading {
        color: $text-white;
        font-family: $theme-font;
        text-transform: uppercase;
        font-weight: lighter;
        font-size: 14px;
        border-bottom: 1px solid #323639;
        box-sizing: border-box;
    }

    .mat-menu-content {
        background: none;
        width: 100%;
    }

    .download__checkbox--group {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: space-between;
    }

}

.share__popup {
    height: max-content;
}

.download__popup--actions {
    text-align: center;

    .download__btn {
        width: 100px;
        margin: 15px auto;
        box-sizing: border-box;
        background: $button-disabled;
        color: $text-disabled;
        cursor: pointer;
        border: 1px solid $text-disabled;
    }
}

.download__popup:before {
    position: absolute;
    content: "";
    background: #ff7b00;
    width: 1px;
    height: 40px;
    right: 0;
    top: 0;
}

.download__popup:after {
    position: absolute;
    content: "";
    background: #ff7b00;
    width: 40px;
    height: 1px;
    top: 0;
    right: 0;
}

//active setting menu item
.active-settings>span>span {
    color: #ff7619 !important;
}

.live__recordings--icon {
    font-size: 70px;
    position: absolute;
    left: -4px;
    top: -11px;
    animation: none;
}

// keyframes
@keyframes blink {
    to {
        visibility: hidden;
    }
}



// more recrdings captions
.recorder__videos--morecaptions {
    background: #494949;
    padding: 10px;

    .mat-menu-content {
        width: auto;
        background: none;
        display: flex;
        flex-wrap: wrap;
        min-height: 50px;
        max-width: 100px;

        span {
            color: #fff;
            font-size: 10px;
            background: black;
            padding: 4px;
            border-radius: 3px;
            margin-right: 3px;
            margin-bottom: 3px;
            max-height: 25px;
            box-sizing: border-box;
        }
    }
}

// progressbar styles
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
    background-color: $theme-color;
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
    background-color: $theme-bg5;
}

//End progressbar styles

.sticky__element {
    top: 60px;
    right: 20px;
    //background: #323639;
    position: fixed;
    padding: 10px;
    //box-shadow: 0 8px 6px -6px $black-color;
    margin-top: 0;
    z-index: 1;
    box-sizing: border-box;
    background: transparent !important;
    box-shadow: none !important;     
}

// line-clamp
.line__clamp {
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: relative;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.add__user--layout {
    .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
        top: calc(100% - -0.33em);
    }
}

// ng slider styles
.left-handle,
.right-handle {
    background: #fff !important;
    border: 7px solid #ff7b00 !important;
}

// ng slider styles end

// default tabs style

.mat-tab-label {
    color: $text-white;
}

// default tabs style ends

// Add new host component styles
.addNewHost__advancedOptions .mat-expansion-panel-body {
    padding: 0;
}

// Add new host component styles ends
.mat-label {
    color: $text-white;
}

// .monitor_toggle_container .mat-tab-label:last-child {
//     margin-left: auto !important;
//     width: 50px !important;
//     height: 60px !important;
//     background-color: currentColor;
//     margin-top: 0 !important;
//     margin-right: 0 !important;
// }
i.ci.icon-help-available:hover {
    cursor: pointer;
}
mat-option {
    min-height: 40px !important;
}
.audio__field {
    .mat-select-value {
        padding: 0 !important;
    }
}
.metadata__field  {
    .mat-select-arrow {
        color: $text-placeholder;
        width: 7px;
        height: 7px;
        display: inline-block;
        background: transparent;
        border-right: 2px solid $text-placeholder;
        border-bottom: 2px solid $text-placeholder;
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg);
        vertical-align:top;        
    }
   
    &.mat-focused {
        .mat-select-arrow {
            transform: rotate(225deg) !important;
            vertical-align: bottom !important;
        }
    }
    .mat-select {
        .mat-select-value {            
            //visibility: hidden !important;
            .mat-select-value-text {
                visibility: hidden;
            }
        }
    }
    .mat-form-field-label {
        transform: unset !important;
    }
}
.body-max-height {
    height: auto; 
    max-height: 450px !important;
}
@media all and (max-width: 1280px) {
    .body-max-height {
        height: auto; 
        max-height: 350px !important;
    }
}
 
    .mat-dialog-container {
        .mat-select-value {
            max-width: unset !important;
          }
    } 

    