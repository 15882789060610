@import '../../../sass/mixins';
@import '../../../sass/variables';

// ======================= Roboto Font =============
@font-face {
    font-family: 'Roboto-BlackItalic';
    src: url('./Roboto-BlackItalic.eot');
    src: url('./Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./Roboto-BlackItalic.woff2') format('woff2'),
        url('./Roboto-BlackItalic.woff') format('woff'),
        url('./Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Black';
    src: url('./Roboto-Black.eot');
    src: url('./Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('./Roboto-Black.woff2') format('woff2'),
        url('./Roboto-Black.woff') format('woff'),
        url('./Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-MediumItalic';
    src: url('./Roboto-MediumItalic.eot');
    src: url('./Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./Roboto-MediumItalic.woff2') format('woff2'),
        url('./Roboto-MediumItalic.woff') format('woff'),
        url('./Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Italic';
    src: url('./Roboto-Italic.eot');
    src: url('./Roboto-Italic.eot?#iefix') format('embedded-opentype'),
        url('./Roboto-Italic.woff2') format('woff2'),
        url('./Roboto-Italic.woff') format('woff'),
        url('./Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url('./Roboto-Light.eot');
    src: url('./Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('./Roboto-Light.woff2') format('woff2'),
        url('./Roboto-Light.woff') format('woff'),
        url('./Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-BoldItalic';
    src: url('./Roboto-BoldItalic.eot');
    src: url('./Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./Roboto-BoldItalic.woff2') format('woff2'),
        url('./Roboto-BoldItalic.woff') format('woff'),
        url('./Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('./Roboto-Bold.eot');
    src: url('./Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Roboto-Bold.woff2') format('woff2'),
        url('./Roboto-Bold.woff') format('woff'),
        url('./Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('./Roboto-Medium.eot');
    src: url('./Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('./Roboto-Medium.woff2') format('woff2'),
        url('./Roboto-Medium.woff') format('woff'),
        url('./Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-LightItalic';
    src: url('./Roboto-LightItalic.eot');
    src: url('./Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./Roboto-LightItalic.woff2') format('woff2'),
        url('./Roboto-LightItalic.woff') format('woff'),
        url('./Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-ThinItalic';
    src: url('./Roboto-ThinItalic.eot');
    src: url('./Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('./Roboto-ThinItalic.woff2') format('woff2'),
        url('./Roboto-ThinItalic.woff') format('woff'),
        url('./Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Thin';
    src: url('./Roboto-Thin.eot');
    src: url('./Roboto-Thin.eot?#iefix') format('embedded-opentype'),
        url('./Roboto-Thin.woff2') format('woff2'),
        url('./Roboto-Thin.woff') format('woff'),
        url('./Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Regular.eot');
    src: url('./Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Roboto-Regular.woff2') format('woff2'),
        url('./Roboto-Regular.woff') format('woff'),
        url('./Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

