$theme-color: #ff7b00;
$second-color: #202124; //LoginSecBg
//#282a2d; == rgba( $second-color, .7 ) from #202124;
$third-color: #323639; //loginPageBg
$fourth-color: #282a2d;
$theme-font: 'Montserrat';
$text-white:#ffffff;
$white-color:#ffffff;
$black-color:#000000; //lighten($black-color, 80%); == #ccc OR lighten($black-color, 50%); == #808080
$text-red:#ff475d;
$btn-text-size:1rem;
$live-color: #00d76a;
$button-default: #666666;
$button-disabled: #444444;
$text-disabled: #808080;
$text-light-gray :#b4c2c8;
$text-gray :#777777;
$player-button-default: #575757;
$btn-bg-color:#e26f30;
// darken( $second-color, 10% )
// lighten( $second-color, 10% )
//lighten( $black-color, 50% ) == #808080

$theme-bg1: #202124;
$theme-bg2:#323639;
$theme-bg3:#555555;
$theme-bg4:#666666;
$theme-bg5:#aaaaaa;
$theme-color: #ff7b00;
$input-bg:#666666;
$input-bg2:#454545;
$theme-font: 'Montserrat';
$text-white:#ffffff;
$white-color:#ffffff;
$black-color:#000000; //lighten($black-color, 80%); == #ccc OR lighten($black-color, 50%); == #808080
$btn-text-size:1rem;
$live-color: #00d76a;
$button-default: #666666;
$button-disabled2: #494949;
$text-disabled: #808080;
$text-placeholder: #cccccc;
// Modal Pop up
$modal-bg:#36373a;
$modal-radius:2px;
$modal-title-size:20px;
$modal-title-color:#aaaaaa;
// Select Nav
$nav-active-bg:#4c331c;
$nav-active-bg-border-color:#ff7b00;
